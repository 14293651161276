import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CButton, CSpinner } from "@coreui/react";
import { BrandColor } from "./Color";
import { cilCloudUpload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const imagePlaceholder = require('../assets/images/image_placeholder.gif');
const MySwal = withReactContent(Swal)

const ImageInput = (props) => {
  const { onChange, disabled, value } = props;
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();

  const [source, setSource] = useState(value);

  useEffect(()=>{
    setSource(value)
  }, [value])

  const handleFileChange = (event) => {
    setLoading(true);
    
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("email", process.env.REACT_APP_CDN_EMAIL);
    formData.append("password", process.env.REACT_APP_CDN_PASSWORD);

    axios.post(process.env.REACT_APP_CDN_URL, formData)
    .then((response) => {
      if (response.data.status) {
        // berhasil
        setSource(response.data.data.url);
        onChange(response.data.data);
      } else {
        // gagal
        MySwal.fire({
          icon: 'danger',
          text: response.data.error,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch((error) => {
      MySwal.fire({
        icon: 'danger',
        text: 'Terjadi kesalahan, silahkan coba lagi!',
        confirmButtonColor: BrandColor(),
        confirmButtonText: 'Close'
      })
      setLoading(false);
    });
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  return (
    <div className="VideoInput">
      <input
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        accept=".jpg,.jpeg,.png,.gif"
      />
      
      <div style={{position:'relative'}}>
        {
          loading &&
          <div className="Videoinput_loader">
            <div className="Videoinput_loader_icon">
              <CSpinner color="info" />
              Mengupload File...
            </div>
          </div>
        }
      
        <img
          style={{width:'100%', maxHeight:400, objectFit:'contain'}}
          alt=''
          className='img-fluid'
          src={source?source:imagePlaceholder.default}
        />
      </div>
      <div className="VideoInput_footer">
        <CButton
          color='success'
          onClick={handleChoose}
          className="text-white"
          disabled={loading||disabled}
        >
          <CIcon icon={cilCloudUpload} /> &nbsp; {!source?'Upload':'Change'} Image
        </CButton>
      </div>
    </div>
  );
}

ImageInput.propTypes = {
	onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.any
}
ImageInput.defaultProps = {
	onChange: null,
  disabled: false,
  value: ''
};

export default ImageInput