import Dashboard from './views/dashboard/Dashboard'
import PlacementForm from './views/property/PlacementForm'
import PlacementList from './views/property/PlacementList'
import ChannelForm from './views/property/ChannelForm'
import ChannelList from './views/property/ChannelList'
import InventoryForm from './views/property/InventoryForm'
import InventoryList from './views/property/InventoryList'
import CategoryList from './views/property/CategoryList'
import ItemList from './views/item/ItemList'
import ItemForm from './views/item/ItemForm'
import ProductList from './views/product/ProductList'
import ProductForm from './views/product/ProductForm'
import BannerList from './views/banner/BannerList'
import BannerForm from './views/banner/BannerForm'
import ArticleCategoryList from './views/article/ArticleCategoryList'
import ArticleCategoryForm from './views/article/ArticleCategoryForm'
import ArticleList from './views/article/ArticleList'
import ArticleForm from './views/article/ArticleForm'

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/channel', exact: true, name: 'Channel', component: ChannelList },
  { path: '/channel/add', name: 'Add', component: ChannelForm },
  { path: '/channel/edit/:channel_id', name: 'Edit', component: ChannelForm },
  { path: '/inventory', exact: true, name: 'Inventory', component: InventoryList },
  { path: '/inventory/add', name: 'Add', component: InventoryForm },
  { path: '/inventory/edit/:inventory_id', name: 'Edit', component: InventoryForm },
  { path: '/placement', exact: true, name: 'Placement', component: PlacementList },
  { path: '/placement/add', name: 'Add', component: PlacementForm },
  { path: '/placement/edit/:placement_id', name: 'Edit', component: PlacementForm },
  { path: '/category', exact: true, name: 'Category', component: CategoryList },
  { path: '/item', exact: true, name: 'Item', component: ItemList },
  { path: '/item/add', name: 'Add', component: ItemForm },
  { path: '/item/edit/:item_id', name: 'Edit', component: ItemForm },
  { path: '/product', exact: true, name: 'Product', component: ProductList },
  { path: '/product/add', name: 'Add', component: ProductForm },
  { path: '/product/edit/:product_id', name: 'Edit', component: ProductForm },
  { path: '/banner', exact: true, name: 'Banner', component: BannerList },
  { path: '/banner/add', name: 'Add', component: BannerForm },
  { path: '/banner/edit/:banner_id', name: 'Edit', component: BannerForm },
  { path: '/article-category', exact: true, name: 'Article Category', component: ArticleCategoryList },
  { path: '/article-category/add', name: 'Add', component: ArticleCategoryForm },
  { path: '/article-category/edit/:article_category_id', name: 'Edit', component: ArticleCategoryForm },
  { path: '/article', exact: true, name: 'Article', component: ArticleList },
  { path: '/article/add', name: 'Add', component: ArticleForm },
  { path: '/article/edit/:article_id', name: 'Edit', component: ArticleForm },
]

export default routes
