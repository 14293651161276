import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilSpeedometer,
  cilCog,
  cilList,
  cilLayers,
  cilNewspaper,
  cilImage
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'

export const nav_su = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
  },
  {
    component: CNavGroup,
    name: 'Product Property',
    to: '/property',
    icon: <CIcon icon={cilCog} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Category',
        to: '/category',
      },
      {
        component: CNavItem,
        name: 'Channel',
        to: '/channel',
      },
      {
        component: CNavItem,
        name: 'Inventory',
        to: '/inventory',
      },
      {
        component: CNavItem,
        name: 'Placement',
        to: '/placement',
      }
    ]
  },
  {
    component: CNavItem,
    name: 'Product Item',
    to: '/item',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Product',
    to: '/product',
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Homepage Banner',
    to: '/banner',
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />
  },
  {
    component: CNavGroup,
    name: 'Article Management',
    to: '/article',
    icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Article Category',
        to: '/article-category',
      },
      {
        component: CNavItem,
        name: 'Article',
        to: '/article',
      }
    ]
  },
]