import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormLabel,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilPencil,
  cilPlus,
  cilTrash
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'
import { useHistory } from 'react-router-dom'
import SelectChannel from 'src/components/SelectChannel'
import SelectInventory from 'src/components/SelectInventory'
import SelectPlacement from 'src/components/SelectPlacement'

const MySwal = withReactContent(Swal)
const pageRow = 10

const ItemList = () => {
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })
  const [channel, setChannel] = useState('')
  const [inventory, setInventory] = useState('')
  const [placement, setPlacement] = useState('')

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ],
      filter: [
        {
          field: "channel_id",
          operator: "=",
          value: channel?.channel_id
        },
        {
          field: "inventory_id",
          operator: "=",
          value: inventory?.inventory_id
        },
        {
          field: "placement_id",
          operator: "=",
          value: placement?.placement_id
        }
      ]
    }

    http.get(`item`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setData((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  }

  const deleteData = (item_id) => {
    setLoading(true);

    http.put('item/delete/'+item_id)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        getData()

        MySwal.fire({
          heightAuto: false,
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          heightAuto: false,
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(()=>{
    getData()
  }, [channel, inventory, placement])

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol md={8}>
              <h4 id="traffic" className="card-title mb-0">Item</h4>
              <div className="small text-medium-emphasis">List</div>
            </CCol>
            <CCol md={4} className="d-none d-md-block">
              <div className='d-flex justify-content-end'>
                <CButton
                  color="info" 
                  className="float-end text-white ms-4" 
                  onClick={()=>history.push({
                    pathname: `/item/add`,
                    state: { type: 'add' }
                  })}
                >
                  <CIcon icon={cilPlus} /> Create Item
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Channel</CFormLabel>
                <SelectChannel
                  value={channel}
                  onSelect={(val)=>{
                    setChannel(val)
                    if(channel!==val){
                      setInventory('')
                      setPlacement('')
                    }
                  }}
                />
              </div>
            </CCol>
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Inventory</CFormLabel>
                <SelectInventory
                  value={inventory}
                  onSelect={(data)=>{
                    setInventory(data)
                    if(inventory!==data){
                      setPlacement('')
                    }
                  }}
                  disabled={channel===''?true:false}
                  channel_id={channel.channel_id}
                />
              </div>
            </CCol>
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Placement</CFormLabel>
                <SelectPlacement
                  value={placement}
                  onSelect={(data)=>{
                    setPlacement(data)
                  }}
                  disabled={inventory===''?true:false}
                  inventory_id={inventory.inventory_id}
                />
              </div>
            </CCol>
          </CRow>
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Name</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Qty</CTableHeaderCell>
                  <CTableHeaderCell>Property</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Image</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="6" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell><strong>{item.item_name}</strong></CTableDataCell>
                            <CTableDataCell className="text-center">{item.quantity}</CTableDataCell>
                            <CTableDataCell>{item.channel?.channel_name?item.channel?.channel_name:'Undefined'} {item.inventory?.inventory_name} {item.placement?.placement_name}</CTableDataCell>
                            <CTableDataCell width="30px"><img src={item.image} height="40px" alt=""/></CTableDataCell>
                            <CTableDataCell className="text-center"><CBadge color={item.status===1?"success":"danger"} shape="rounded-pill">{item.status===1?"Active":"Inactive"}</CBadge></CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CButton 
                                color="warning" 
                                className="text-white" 
                                size='sm'
                                onClick={()=>history.push({
                                  pathname: '/item/edit/'+item.item_id,
                                  state: { type: 'edit', data: item }
                                })}
                              >
                                <CIcon icon={cilPencil} />&nbsp; Edit
                              </CButton>
                              <CButton 
                                color="danger" 
                                className="text-white ms-2" 
                                size='sm'
                                onClick={()=>deleteData(item.item_id)}
                              >
                                <CIcon icon={cilTrash} />&nbsp; Delete
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

export default ItemList