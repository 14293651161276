import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormLabel,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'
import SelectChannel from 'src/components/SelectChannel'
import SelectInventory from 'src/components/SelectInventory'
import SelectPlacement from 'src/components/SelectPlacement'

const MySwal = withReactContent(Swal)
const pageRow = 10

const ItemList = (props) => {
  const { onSelect, value } = props
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })
  const [channel, setChannel] = useState('')
  const [inventory, setInventory] = useState('')
  const [placement, setPlacement] = useState('')
  const [itemSelected, setItemSelected] = useState([])

  useEffect(()=>{
    setItemSelected(value)
  }, [value])

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ],
      filter: [
        {
          field: "channel_id",
          operator: "=",
          value: channel?.channel_id
        },
        {
          field: "item_id",
          operator: "=",
          value: inventory?.item_id
        },
        {
          field: "placement_id",
          operator: "=",
          value: placement?.placement_id
        }
      ]
    }

    http.get(`item`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setData((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  }

  const checkSelect = (item_id) => {
    const check = itemSelected.findIndex(x=>x.item_id===item_id)
    if(check >= 0){
      return true
    }else{
      return false
    }
  }

  const onSelectItem = (item) => {
    var newSelected = [...itemSelected]
    newSelected.push(item)
    setItemSelected(newSelected)
    onSelect(newSelected)
  }

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(()=>{
    getData()
  }, [channel, inventory, placement])

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Channel</CFormLabel>
                <SelectChannel
                  value={channel}
                  onSelect={(val)=>{
                    setChannel(val)
                    if(channel!==val){
                      setInventory('')
                      setPlacement('')
                    }
                  }}
                />
              </div>
            </CCol>
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Inventory</CFormLabel>
                <SelectInventory
                  value={inventory}
                  onSelect={(data)=>{
                    setInventory(data)
                    if(inventory!==data){
                      setPlacement('')
                    }
                  }}
                  disabled={channel===''?true:false}
                  channel_id={channel.channel_id}
                />
              </div>
            </CCol>
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Placement</CFormLabel>
                <SelectPlacement
                  value={placement}
                  onSelect={(data)=>{
                    setPlacement(data)
                  }}
                  disabled={inventory===''?true:false}
                  item_id={inventory.item_id}
                />
              </div>
            </CCol>
          </CRow>
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Name</CTableHeaderCell>
                  <CTableHeaderCell>Description</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Qty</CTableHeaderCell>
                  <CTableHeaderCell>Property</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="6" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell><strong>{item.item_name}</strong></CTableDataCell>
                            <CTableDataCell>{item.item_description}</CTableDataCell>
                            <CTableDataCell className="text-center">{item.quantity}</CTableDataCell>
                            <CTableDataCell>{item.channel?.channel_name?item.channel?.channel_name:'Undefined'} {item.inventory?.inventory_name} {item.placement?.placement_name}</CTableDataCell>
                            <CTableDataCell className="text-center"><CBadge color={item.status===1?"success":"danger"} shape="rounded-pill">{item.status===1?"Active":"Inactive"}</CBadge></CTableDataCell>
                            <CTableDataCell className="text-center">
                              <div className="d-flex align-items-center justify-content-center">
                                <CButton
                                  color={checkSelect(item.item_id)?'secondary':'success'} 
                                  className="text-white" 
                                  size='sm'
                                  disabled={checkSelect(item.item_id)}
                                  onClick={()=>onSelectItem(item)}
                                >
                                  {checkSelect(item.item_id)?'Selected':'Select'}
                                </CButton>
                                {
                                  checkSelect(item.item_id) &&
                                  <CButton 
                                    color={'danger'} 
                                    className="text-white ms-2" 
                                    size='sm'
                                    onClick={()=>{
                                      const idcCheck = itemSelected.findIndex(x=>x.item_id===item.item_id)
                                      const newData = [...itemSelected]
                                      newData.splice(idcCheck, 1)
                                      setItemSelected(newData)
                                      onSelect(newData)
                                    }}
                                  >
                                    X
                                  </CButton>
                                }
                              </div>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

export default ItemList