export const BrandColor = () => {
	return '#2d6ab6';
}

export const DangerColor = () =>{
  return '#e55353'
}

export const SuccessColor = () =>{
  return '#2eb85c'
}

export const WarningColor = () =>{
  return '##f8b114'
}