import { AES, enc } from "crypto-js"
export const Securitykey = "Med14c4rt@021";

export const decrypt = (data) => {
  if(data){
    var bytes = AES.decrypt(data, Securitykey);
    var decryptedData = bytes.toString(enc.Utf8);
    return decryptedData;
  }else{
    return null;
  }
}


export const encrypt = (data) => {
  return AES.encrypt(data, Securitykey).toString();
}