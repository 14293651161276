import React, { useEffect, useState } from 'react'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilSave, cilX
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ImageInput from 'src/components/ImageInput'

const MySwal = withReactContent(Swal)

const ArticleForm = () => {
  const history = useHistory()
  const { article_id } = useParams()
  const { state } = useLocation()
  const { type, data } = state
	const [loading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState({
    title: '',
    status: 1,
    slug: '',
    article_category_id: '',
    author: '',
    content: '',
    image: ''
  })
  const [category, setCategory] = useState([])

  const generateSlug = (text) => {
    const slug = text.toString().toLowerCase()
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .replace(/\s+/g, '-')
    .replace(/\-\-+/g, '-')
    .replace(/[^\w\-]+/g, '');

    setDataForm(state => ({...state, slug: slug}))
  }

  const getCategory = () => {
    var data_post = {
      page: 1,
      rows: 100,
      order: [
        {
          field: "article_category_name",
          sort: "ASC"
        }
      ]
    }

    http.get(`article_category`,
      { params: data_post }
    )
    .then((res) => {
      console.log('res article_category', res.data)
      if(res.data.status) {
       setCategory(res.data.data.data) 
      }
    })
    .catch((error) => {
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      history.goBack()
    })

  }

  useEffect(()=>{
    getCategory()

    if(type==='edit'){
      setDataForm(state => ({
        ...state,
        title: data.title,
        status: data.status,
        slug: data.slug,
        author: data.author,
        content: data.content,
        image: data.image,
        article_category_id: data.article_category_id
      }))
    }
  }, [])

  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {type==='add'?'Add':'Edit'} Article
              </h4>
              <div className="small text-medium-emphasis">Article Data</div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Title</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Title"
                  value={dataForm.title}
                  onChange={(txt)=>setDataForm(state => ({...state, title: txt.target.value}))}
                />
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Slug</CFormLabel>
                <CInputGroup>
                  <CFormInput
                    type="input"
                    placeholder="Slug"
                    value={dataForm.slug}
                    onChange={(txt)=>generateSlug(txt.target.value)}
                  />
                  <CInputGroupText className="pointer" onClick={()=>generateSlug(dataForm.title)}>Generate</CInputGroupText>
                </CInputGroup>
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Article Category</CFormLabel>
                <CFormSelect
                  value={dataForm.article_category_id}
                  onChange={(val)=>setDataForm(state=>({...state, article_category_id: val.target.value}))}
                >
                  <option value="">Select</option>
                  {
                    category.map((item, index) => (
                      <option key={index} value={item.article_category_id}>{item.article_category_name}</option>
                    ))
                  }
                </CFormSelect>
              </div>
              <div className="mb-3">
                <CFormLabel>Author</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Author"
                  value={dataForm.author}
                  onChange={(txt)=>setDataForm(state => ({...state, author: txt.target.value}))}
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Status</CFormLabel>
                <div className="d-flex flex-row">
                  <CFormCheck
                    type="radio"
                    label="Publish"
                    checked={dataForm.status===1}
                    onChange={()=>setDataForm(state=>({...state, status: 1}))}
                  />
                  <CFormCheck
                    type="radio"
                    label="Unpublish"
                    className="ms-3"
                    checked={dataForm.status===0}
                    onChange={()=>setDataForm(state=>({...state, status: 0}))}
                  />
                </div>
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Image</CFormLabel>
                <ImageInput
                  disabled={loading} 
                  value={dataForm.image}
                  onChange={(data)=>setDataForm((state) => ({...state, image: data.url }))}
                />
              </div>
            </CCol>
            <CCol md={12}>
              <div className="mb-3">
                <CFormLabel>Content</CFormLabel>
                <CKEditor
                  editor={ ClassicEditor }
                  data={dataForm.content}
                  height={100}
                  onChange={ ( event, editor ) => {
                    const data = editor.getData()
                    setDataForm(state => ({...state, content: data}))
                  }}
                />
              </div>
            </CCol>
          </CRow>
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
          <CButton 
            color="danger" 
            className="text-white ms-2"
            onClick={()=>history.goBack()}
          >
            <CIcon icon={cilX} /> &nbsp; Cancel
          </CButton>
        </CCardBody>
      </CCard>
    </>
  )

  function create() {
    setLoading(true);
    var data_post = {
      title: dataForm.title,
      slug: dataForm.slug,
      article_category_id: dataForm.article_category_id,
      status: dataForm.status,
      author: dataForm.author,
      content: dataForm.content,
      image: dataForm.image
    }

    http.post('article', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      title: dataForm.title,
      slug: dataForm.slug,
      article_category_id: dataForm.article_category_id,
      status: dataForm.status,
      author: dataForm.author,
      content: dataForm.content,
      image: dataForm.image
    }
    http.put('article/'+article_id, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default ArticleForm