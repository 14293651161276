import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CButton,
  CCol,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import {
  cilAccountLogout,
  cilLockLocked,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import Cookies from 'js-cookie'
import avatar8 from './../../assets/images/avatars/default-profile.jpeg'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { decrypt, encrypt } from 'src/service/Cryptograph'
import Loader from '../Loader'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from '../Color'
import http from 'src/service/Request'

const MySwal = withReactContent(Swal)

const AppHeaderDropdown = () => {
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const history = useHistory()
  const dispatch = useDispatch()
  const [modalEdit, setModalEdit] = useState(false)
  const [modalPassword, setModalPassword] = useState(false)
  const [fullname, setFullname] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState(null)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')

  console.log('user', user)

  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('user')
    dispatch({ type: 'set', user: {} });
    history.replace('/login')
  }

  const editProfile = () => {
    setLoading(true)
    const data_post = {
      user_id: userId,
      email: email,
      fullname: fullname,
      phone: phone,
      address: address
    }
    http.put('users', data_post)
    .then(res => {
      if(res.data.status){
        setModalEdit(false)
        Cookies.set('user', encrypt(JSON.stringify(res.data.auth)), { expires: 1 });
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  const editPassword = () => {
    if(password==='' || password2===''){
      MySwal.fire({
        icon: 'warning',
        text: 'Password required',
        confirmButtonColor: BrandColor(),
        confirmButtonText: 'Close'
      })
      return false
    }

    if(password!==password2){
      MySwal.fire({
        icon: 'warning',
        text: 'Password not match',
        confirmButtonColor: BrandColor(),
        confirmButtonText: 'Close'
      })
      return false
    }

    setLoading(true)
    const data_post = {
      user_id: userId,
      password: password
    }
    http.put('users/', data_post)
    .then(res => {
      if(res.data.status){
        setModalPassword(false)
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  useEffect(()=>{
    const user = Cookies.get("user") ? JSON.parse(decrypt(Cookies.get("user"))) : null

    if(user){
      setFullname(user?.fullname)
      setEmail(user?.email)
      setUserId(user?.user_id)
      setPhone(user?.phone)
      setAddress(user?.address)
    }
  }, [modalEdit])

  return (
    <>
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <div className='d-flex align-items-center'>
            <CAvatar src={avatar8} size="md" />
            <div className='ms-2'>
              <strong style={{fontSize:14, color:'#000'}}>{user?.name}</strong>
              <div style={{fontSize:12, lineHeight:1, textTransform: 'capitalize'}}>{user?.ms_admin?.ms_admin_name}</div>
            </div>
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0 mt-2" placement="bottom-end">
          {/* <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader> */}
          {/* <CDropdownItem className='pointer' onClick={()=>setModalEdit(true)}>
            <CIcon icon={cilUser} className="me-2" />
            Edit Profile
          </CDropdownItem>
          <CDropdownItem className='pointer' onClick={()=>setModalPassword(true)}>
            <CIcon icon={cilLockLocked} className="me-2" />
            Change Password
          </CDropdownItem> */}
          {/* <CDropdownDivider /> */}
          <CDropdownItem className='pointer' onClick={()=>logout()}>
            <CIcon icon={cilAccountLogout} className="me-2" />
            Log Out
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <CModal alignment='center' visible={modalEdit} size="lg">
        <Loader visible={loading}/>
        <CModalHeader closeButton={false}>
          <CModalTitle>Edit Profile</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <div className="mb-3">
                <CFormLabel>Email</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Ads Name"
                  value={email}
                  onChange={(txt)=>setEmail(txt.target.value)}
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Full Name</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Full Name"
                  value={fullname}
                  onChange={(txt)=>setFullname(txt.target.value)}
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Phone</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Phone"
                  value={phone}
                  onChange={(txt)=>setPhone(txt.target.value)}
                />
              </div>
            </CCol>
            <CCol>
              <div className="mb-3">
                <CFormLabel>Address</CFormLabel>
                <CFormTextarea
                  type="input"
                  placeholder="Address"
                  value={address}
                  onChange={(txt)=>setAddress(txt.target.value)}
                  rows={8}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" className='text-white' onClick={() => setModalEdit(false)}>
            Cancel
          </CButton>
          <CButton color="info" className='text-white' onClick={()=>editProfile()}>Save changes</CButton>
        </CModalFooter>
      </CModal>

      <CModal alignment='center' visible={modalPassword}>
        <Loader visible={loading}/>
        <CModalHeader closeButton={false}>
          <CModalTitle>Change Password</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="mb-3">
            <CFormLabel>Password</CFormLabel>
            <CFormInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(txt)=>setPassword(txt.target.value)}
            />
          </div>
          <div className="mb-3">
            <CFormLabel>Retype Password</CFormLabel>
            <CFormInput
              type="password"
              placeholder="Retype Password"
              value={password2}
              onChange={(txt)=>setPassword2(txt.target.value)}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" className='text-white' onClick={() => setModalPassword(false)}>
            Cancel
          </CButton>
          <CButton color="info" className='text-white' onClick={()=>editPassword()}>Save changes</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default AppHeaderDropdown
