import { CBadge, CCol, CModal, CModalBody, CModalHeader, CModalTitle, CRow } from "@coreui/react"
import React, { useEffect, useState } from "react"
import styled from 'styled-components';
import CIcon from "@coreui/icons-react";
import { cilX } from "@coreui/icons";
import PlacementList from "./PlacementList";

const SelectBox = styled.div`
  cursor: pointer
`;

const Placeholder = styled.div`
  color: #8b93a2
`;

const SelectPlacement = (props) => {
  const [modal, setModal] = useState(false)
  const [value, setValue] = useState('')

  useEffect(()=>{
    setValue(props.value?props.value:'')
  }, [props.value])

  return(
    <div>
      <SelectBox style={props.disabled?{cursor:'default'}:{}}>
        <div className="form-select" style={props.disabled?{backgroundColor:'#eee'}:{}}>
          <CRow>
            <CCol>
            <CRow>
              <CCol>
                <div onClick={()=>!props.disabled?setModal(true):null}>
                  {
                    value===''?(
                      <Placeholder>
                        Select Placement
                      </Placeholder>
                    ):(
                      <div>{value.placement_name}</div>
                    )
                  }
                </div>
              </CCol>
              {
                value!=="" &&
                <CCol xs={'auto'}>
                  <div className="me-2" onClick={()=>{
                    setValue('')
                    props.onSelect('')
                  }}>
                    <CIcon icon={cilX}/>
                  </div>
                </CCol>
              }
            </CRow>
            </CCol>
          </CRow>
        </div>
      </SelectBox>

      <CModal
        alignment="center"
        size="lg"
        visible={modal}
        onClose={() => setModal(false) }
      >
        <CModalHeader>
          <CModalTitle>Select Placement</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <PlacementList
            channel_id={props.channel_id}
            value={value.length===0?[]:value}
            inventory_id={props.inventory_id}
            onSelect={(data)=>{
              setValue(data)
              props.onSelect(data)
              setModal(false)
            }}
          />
        </CModalBody>
      </CModal>
    </div>
  )
}

export default SelectPlacement