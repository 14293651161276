import React, { useEffect, useState } from 'react'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilSave, cilX
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import SelectChannel from 'src/components/SelectChannel'
import SelectInventory from 'src/components/SelectInventory'
import SelectPlacement from 'src/components/SelectPlacement'
import ImageInput from 'src/components/ImageInput'

const MySwal = withReactContent(Swal)

const ItemForm = () => {
  const history = useHistory()
  const { item_id } = useParams()
  const { state } = useLocation()
  const { type, data } = state
	const [loading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState({
    name: '',
    status: 1,
    description: '',
    qty: 1,
    image: ''
  })
  const [channel, setChannel] = useState('')
  const [inventory, setInventory] = useState('')
  const [placement, setPlacement] = useState('')
  const [isGenerate, setIsGenerate] = useState(false)

  useEffect(()=>{
    if(type==='edit'){
      setDataForm(state => ({
        ...state,
        name: data.item_name,
        status: data.status,
        description: data.item_description,
        qty: data.quantity,
        image: data.image
      }))
      setIsGenerate(data.is_generate_name)
      setChannel(data.channel?data.channel:'')
      setInventory(data.inventory?data.inventory:'')
      setPlacement(data.placement?data.placement:'')
    }
  }, [])

  useEffect(()=>{
    if(isGenerate){
      var name = '';
      name += channel!==''?channel.channel_name:''
      name += inventory!==''?' '+inventory.inventory_name:''
      name += placement!==''?' '+placement.placement_name:''
      setDataForm(state=>({...state, name: name}))
    }
  }, [isGenerate, channel, inventory, placement])

  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {type==='add'?'Add':'Edit'} Item
              </h4>
              <div className="small text-medium-emphasis">Item Data</div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Channel</CFormLabel>
                <SelectChannel
                  value={channel}
                  onSelect={(val)=>{
                    setChannel(val)
                    if(channel!==val){
                      setInventory('')
                      setPlacement('')
                    }
                  }}
                />
              </div>
            </CCol>
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Inventory</CFormLabel>
                <SelectInventory
                  value={inventory}
                  onSelect={(data)=>{
                    setInventory(data)
                    if(inventory!==data){
                      setPlacement('')
                    }
                  }}
                  disabled={channel===''?true:false}
                  channel_id={channel.channel_id}
                />
              </div>
            </CCol>
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Placement</CFormLabel>
                <SelectPlacement
                  value={placement}
                  onSelect={(data)=>{
                    setPlacement(data)
                  }}
                  disabled={inventory===''?true:false}
                  inventory_id={inventory.inventory_id}
                />
              </div>
            </CCol>
            <CCol md={8}>
              <div className="mb-3">
                <CFormLabel>Name</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Name"
                  value={dataForm.name}
                  onChange={(txt)=>setDataForm(state => ({...state, name: txt.target.value}))}
                  disabled={isGenerate}
                />
              </div>
              <div className='mb-3'>
                <CFormCheck
                  label="Use Product Property Name"
                  checked={isGenerate?'checked':''}
                  onChange={()=>setIsGenerate(!isGenerate)}
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Quantity</CFormLabel>
                <CFormInput
                  type="number"
                  min={1}
                  placeholder="Quantity"
                  value={dataForm.qty}
                  onChange={(txt)=>setDataForm(state => ({...state, qty: txt.target.value}))}
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Status</CFormLabel>
                <div className="d-flex flex-row">
                  <CFormCheck
                    type="radio"
                    label="Active"
                    checked={dataForm.status===1}
                    onChange={()=>setDataForm(state=>({...state, status: 1}))}
                  />
                  <CFormCheck
                    type="radio"
                    label="Inactive"
                    className="ms-3"
                    checked={dataForm.status===0}
                    onChange={()=>setDataForm(state=>({...state, status: 0}))}
                  />
                </div>
              </div>
            </CCol>
            <CCol md={4}>
              <div className="mb-3">
                <CFormLabel>Image</CFormLabel>
                <ImageInput
                  disabled={loading} 
                  value={dataForm.image}
                  onChange={(data)=>setDataForm((state) => ({...state, image: data.url }))}
                />
              </div>
            </CCol>
            <CCol md={12}>
              <div className="mb-3">
                <CFormLabel>Description</CFormLabel>
                <CFormTextarea
                  placeholder="Description"
                  value={dataForm.description}
                  onChange={(txt)=>setDataForm(state => ({...state, description: txt.target.value}))}
                  rows={9}
                />
              </div>
            </CCol>
          </CRow>
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
          <CButton 
            color="danger" 
            className="text-white ms-2"
            onClick={()=>history.goBack()}
          >
            <CIcon icon={cilX} /> &nbsp; Cancel
          </CButton>
        </CCardBody>
      </CCard>
    </>
  )

  function create() {
    setLoading(true);
    var data_post = {
      channel_id: channel?.channel_id,
      inventory_id: inventory?.inventory_id,
      placement_id: placement?.placement_id,
      item_name: dataForm.name,
      is_generate_name: isGenerate,
      status: dataForm.status,
      item_description: dataForm.description,
      quantity: dataForm.qty,
      image: dataForm.image
    }

    http.post('item', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      channel_id: channel?.channel_id,
      inventory_id: inventory?.inventory_id,
      placement_id: placement?.placement_id,
      item_name: dataForm.name,
      is_generate_name: isGenerate,
      status: dataForm.status,
      item_description: dataForm.description,
      quantity: dataForm.qty,
      image: dataForm.image
    }
    http.put('item/'+item_id, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default ItemForm