import React, { useEffect, useState } from 'react'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilSave, cilX
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ModalSelectItem from 'src/components/ModalSelectItem'
import ImageInput from 'src/components/ImageInput'

const MySwal = withReactContent(Swal)

const ProductForm = () => {
  const history = useHistory()
  const { product_id } = useParams()
  const { state } = useLocation()
  const { type, data } = state
	const [loading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState({
    name: '',
    short_description: '',
    description: '',
    status: 1,
    price: '0',
    image: ''
  })
  const [discountType, setDiscountType] = useState(null)
  const [discount, setDiscount] = useState({
    amount: 0,
    percentage: 0,
    price_after: 0
  })
  const [item, setItem] = useState([])
  const [itemMain, setItemMain] = useState(null)
  
  useEffect(()=>{
    if(type==='edit'){
      setDataForm(state => ({
        ...state,
        name: data.product_name,
        short_description: data.product_short_description,
        description: data.product_description,
        status: data.status,
        price: data.price,
        image: data.image
      }))

      if(data.discount_type){
        setDiscountType(data.discount_type)
        if(data.discount_type==='amount'){
          const amount = parseInt(data.discount_amount) > parseInt(data.price) ? data.price : data.discount_amount
          setDiscount({
            amount: amount,
            percentage: (parseInt(amount)*100)/parseInt(data.price),
            price_after: parseInt(data.price)-parseInt(amount) 
          })
        }else if(data.discount_type==='percentage'){
          const percentage = parseInt(data.discount_amount) > 100 ? 100 : data.discount_amount
          const amount = (parseInt(percentage)*parseInt(data.price))/100
          setDiscount({
            amount: amount,
            percentage: percentage,
            price_after: parseInt(data.price)-parseInt(amount) 
          })
        }else if(data.discount_type==='price_after'){
          const price_after = parseInt(data.discount_amount) > parseInt(data.price) ? data.price : data.discount_amount
          const amount = parseInt(data.price) - parseInt(price_after)
          
          setDiscount({
            amount: amount,
            percentage: (parseInt(amount)*100)/parseInt(data.price),
            price_after: price_after
          })
        }
      }

      setItemMain(data.main_item_id)
      var newItem = data.includes.map(x=>x.item)
      newItem.unshift(data.main_item)
      setItem(newItem)
    }
  }, [])

  const changeDiscountType = (type) => {
    if(type===discountType){
      setDiscountType(null)
    }else{
      setDiscountType(type)
    }
  }

  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {type==='add'?'Add':'Edit'} Product
              </h4>
              <div className="small text-medium-emphasis">Product Data</div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Name</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Name"
                  value={dataForm.name}
                  onChange={(txt)=>setDataForm(state => ({...state, name: txt.target.value}))}
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Short Description</CFormLabel>
                <CFormTextarea
                  placeholder="Short Description"
                  value={dataForm.short_description}
                  onChange={(txt)=>setDataForm(state => ({...state, short_description: txt.target.value}))}
                  rows={3}
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Description</CFormLabel>
                <CFormTextarea
                  placeholder="Description"
                  value={dataForm.description}
                  onChange={(txt)=>setDataForm(state => ({...state, description: txt.target.value}))}
                  rows={9}
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Image</CFormLabel>
                <ImageInput
                  disabled={loading} 
                  value={dataForm.image}
                  onChange={(data)=>setDataForm((state) => ({...state, image: data.url }))}
                />
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Price</CFormLabel>
                <CInputGroup>
                  <CInputGroupText>Rp</CInputGroupText>
                  <CFormInput
                    type="number"
                    min={0}
                    placeholder="Price"
                    value={dataForm.price}
                    onChange={(txt)=>setDataForm(state => ({...state, price: txt.target.value}))}
                  />
                </CInputGroup>
              </div>

              <CRow className='mb-3 align-items-center'>
                <CCol xs={4}>
                  <CFormLabel>Amount Discount</CFormLabel>
                </CCol>
                <CCol xs={8}>
                <div className="d-flex align-items-center">
                  <CFormCheck
                    type="radio"
                    checked={discountType==='amount'}
                    onClick={()=>changeDiscountType('amount')}
                  />
                  <div className='flex-grow-1 ms-3'>
                    <CInputGroup>
                      <CInputGroupText>Rp</CInputGroupText>
                      <CFormInput
                        type="number"
                        min={0}
                        placeholder="Amount Discount"
                        value={discount.amount}
                        onChange={(txt)=>{
                          const amount = parseInt(txt.target.value) > parseInt(dataForm.price) ? dataForm.price : txt.target.value
                          setDiscount({
                            amount: amount,
                            percentage: (parseInt(amount)*100)/parseInt(dataForm.price),
                            price_after: parseInt(dataForm.price)-parseInt(amount) 
                          })
                        }}
                        disabled={dataForm.price==='0'||dataForm.price===''?true:false}
                      />
                    </CInputGroup>
                  </div>
                </div>
                </CCol>
              </CRow>

              <CRow className='mb-3 align-items-center'>
                <CCol xs={4}>
                  <CFormLabel>Percentage Discount</CFormLabel>
                </CCol>
                <CCol xs={8}>
                <div className="d-flex align-items-center">
                  <CFormCheck
                    type="radio"
                    checked={discountType==='percentage'}
                    onClick={()=>changeDiscountType('percentage')}
                  />
                  <div className='flex-grow-1 ms-3'>
                    <CInputGroup>
                    
                      <CFormInput
                        type="number"
                        min={0}
                        max={100}
                        placeholder="Percentage Discount"
                        value={discount.percentage}
                        onChange={(txt)=>{
                          const percentage = txt.target.value > 100 ? 100 : txt.target.value
                          const amount = (parseInt(percentage)*parseInt(dataForm.price))/100
                          setDiscount({
                            amount: amount,
                            percentage: percentage,
                            price_after: parseInt(dataForm.price)-parseInt(amount) 
                          })
                        }}
                        disabled={dataForm.price==='0'||dataForm.price===''?true:false}
                      />
                      <CInputGroupText>%</CInputGroupText>
                    </CInputGroup>
                  </div>
                </div>
                </CCol>
              </CRow>

              <CRow className='mb-3 align-items-center'>
                <CCol xs={4}>
                  <CFormLabel>Price After Discount</CFormLabel>
                </CCol>
                <CCol xs={8}>
                <div className="d-flex align-items-center">
                  <CFormCheck
                    type="radio"
                    checked={discountType==='price_after'}
                    onClick={()=>changeDiscountType('price_after')}
                  />
                  <div className='flex-grow-1 ms-3'>
                    <CInputGroup>
                      <CInputGroupText>Rp</CInputGroupText>
                      <CFormInput
                        type="number"
                        min={0}
                        placeholder="Price After Discount"
                        value={discount.price_after}
                        onChange={(txt)=>{
                          const price_after = parseInt(txt.target.value) > parseInt(dataForm.price) ? dataForm.price : txt.target.value
                          const amount = parseInt(dataForm.price) - parseInt(price_after)
                          
                          setDiscount({
                            amount: amount,
                            percentage: (parseInt(amount)*100)/parseInt(dataForm.price),
                            price_after: price_after
                          })
                        }}
                        disabled={dataForm.price==='0'||dataForm.price===''?true:false}
                      />
                    </CInputGroup>
                  </div>
                </div>
                </CCol>
              </CRow>

              <div className="mb-3">
                <CFormLabel>Status</CFormLabel>
                <div className="d-flex flex-row">
                  <CFormCheck
                    type="radio"
                    label="Active"
                    checked={dataForm.status===1}
                    onChange={()=>setDataForm(state=>({...state, status: 1}))}
                  />
                  <CFormCheck
                    type="radio"
                    label="Inactive"
                    className="ms-3"
                    checked={dataForm.status===0}
                    onChange={()=>setDataForm(state=>({...state, status: 0}))}
                  />
                </div>
              </div>
            </CCol>
          </CRow>
          <hr/>
          <ModalSelectItem
            value={item}
            main={itemMain}
            onSelect={(data) => setItem(data)}
            onSelectMain={(data) => setItemMain(data)}
          />
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
          <CButton 
            color="danger" 
            className="text-white ms-2"
            onClick={()=>history.goBack()}
          >
            <CIcon icon={cilX} /> &nbsp; Cancel
          </CButton>
        </CCardBody>
      </CCard>
    </>
  )

  function create() {
    setLoading(true)
    var data_post = {
      product_name: dataForm.name,
      product_short_description: dataForm.short_description,
      product_description: dataForm.description,
      price: dataForm.price,
      discount_type: discountType,
      discount_amount: discountType?parseInt(discount[discountType]):null,
      status: dataForm.status,
      main_item_id: itemMain,
      item_id: item.map(x=>x.item_id),
      image: dataForm.image
    }

    http.post('product', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      product_name: dataForm.name,
      product_short_description: dataForm.short_description,
      product_description: dataForm.description,
      price: dataForm.price,
      discount_type: discountType,
      discount_amount: discountType?parseInt(discount[discountType]):null,
      status: dataForm.status,
      main_item_id: itemMain,
      item_id: item.map(x=>x.item_id),
      image: dataForm.image
    }
    http.put('product/'+product_id, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default ProductForm