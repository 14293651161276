import { CBadge, CButton, CCol, CFormCheck, CModal, CModalBody, CModalHeader, CModalTitle, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react"
import React, { useEffect, useState } from "react"
import ItemList from "./ItemList"


const ModalSelectItem = (props) => {
  const { value, main, onSelectMain, onSelect } = props;
  const [modal, setModal] = useState(false)

  return(
    <div>
      <CRow className='justify-content-between align-items-center'>
        <CCol xs={'auto'}>
          Product Item List
        </CCol>
        <CCol xs={'auto'}>
          <CButton
            color="info" 
            className="text-white"
            onClick={()=>setModal(true)}
          >
            Add Product Item
          </CButton>
        </CCol>
      </CRow>
      <CTable align="middle" className="mb-4 mt-3 border" hover responsive>
        <CTableHead color="light">
          <CTableRow>
            <CTableHeaderCell>Name</CTableHeaderCell>
            <CTableHeaderCell>Description</CTableHeaderCell>
            <CTableHeaderCell className="text-center">Qty</CTableHeaderCell>
            <CTableHeaderCell>Property</CTableHeaderCell>
            <CTableHeaderCell className="text-center">Image</CTableHeaderCell>
            <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
            <CTableHeaderCell className="text-center">Main</CTableHeaderCell>
            <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        {
          value.length ===0 ? (
            <CTableBody>
              <CTableRow>
                <CTableDataCell colSpan="7" className="text-center">Data empty</CTableDataCell>
              </CTableRow>
            </CTableBody>
          ) : (
            <CTableBody>
              {
                value.map((item, index) => {
                  console.log('item', item)
                  return(
                    <CTableRow key={index}>
                      <CTableDataCell><strong>{item.item_name}</strong></CTableDataCell>
                      <CTableDataCell>{item.item_description}</CTableDataCell>
                      <CTableDataCell className="text-center">{item.quantity}</CTableDataCell>
                      <CTableDataCell>{item.channel?item.channel.channel_name:'Undefined'} {item.inventory?.inventory_name} {item.placement?.placement_name}</CTableDataCell>
                      <CTableDataCell width="30px"><img src={item.image} height="40px" alt=""/></CTableDataCell>
                      <CTableDataCell className="text-center"><CBadge color={item.status===1?"success":"danger"} shape="rounded-pill">{item.status===1?"Active":"Inactive"}</CBadge></CTableDataCell>
                      <CTableDataCell className="text-center">
                        <CFormCheck
                          type="radio"
                          checked={main===item.item_id}
                          onClick={()=>onSelectMain(item.item_id)}
                        />
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <CButton
                          color={'danger'} 
                          className="text-white ms-2" 
                          size='sm'
                          onClick={()=>{
                            const newData = [...value]
                            newData.splice(index, 1)
                            onSelect(newData)
                          }}
                        >
                          X
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  )
                })
              }
            </CTableBody>
          )
        }
      </CTable>

      <CModal
        alignment="center"
        size="lg"
        visible={modal}
        onClose={() => setModal(false) }
      >
        <CModalHeader>
          <CModalTitle>Select Item</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <ItemList
            value={value.length===0?[]:value}
            onSelect={(data)=>{
              onSelect(data)
            }}
          />
        </CModalBody>
      </CModal>
    </div>
  )
}

export default ModalSelectItem