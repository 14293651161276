import React, { useEffect, useState } from 'react'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilSave, cilX
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import SelectChannel from 'src/components/SelectChannel'

const MySwal = withReactContent(Swal)

const InventoryForm = () => {
  const history = useHistory()
  const { inventory_id } = useParams()
  const { state } = useLocation()
  const { type, data } = state
	const [loading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState({
    name: '',
    channel: '',
    status: 1
  })

  useEffect(()=>{
    if(type==='edit'){
      setDataForm(state => ({
        ...state,
        name: data.inventory_name,
        channel: data.channel,
        status: data.status,
      }))
    }
  }, [])

  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {type==='add'?'Add':'Edit'} Inventory
              </h4>
              <div className="small text-medium-emphasis">Inventory Data</div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Channel</CFormLabel>
                <SelectChannel
                  value={dataForm.channel}
                  onSelect={(data)=>setDataForm(state => ({...state, channel:data}))}
                />
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Name</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Name"
                  value={dataForm.name}
                  onChange={(txt)=>setDataForm(state => ({...state, name: txt.target.value}))}
                />
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Status</CFormLabel>
                <div className="d-flex flex-row">
                  <CFormCheck
                    type="radio"
                    label="Active"
                    checked={dataForm.status===1}
                    onChange={()=>setDataForm(state=>({...state, status: 1}))}
                  />
                  <CFormCheck
                    type="radio"
                    label="Inactive"
                    className="ms-3"
                    checked={dataForm.status===0}
                    onChange={()=>setDataForm(state=>({...state, status: 0}))}
                  />
                </div>
              </div>
            </CCol>
          </CRow>
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
          <CButton 
            color="danger" 
            className="text-white ms-2"
            onClick={()=>history.goBack()}
          >
            <CIcon icon={cilX} /> &nbsp; Cancel
          </CButton>
        </CCardBody>
      </CCard>
    </>
  )

  function create() {
    setLoading(true);
    var data_post = {
      inventory_name: dataForm.name,
      channel_id: dataForm.channel.channel_id,
      status: dataForm.status
    }

    http.post('inventory', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      inventory_name: dataForm.name,
      channel_id: dataForm.channel.channel_id,
      status: dataForm.status
    }
    http.put('inventory/'+inventory_id, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default InventoryForm