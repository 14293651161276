import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'

const MySwal = withReactContent(Swal)
const pageRow = 10

const ChannelList = (props) => {
  const { onSelect, value } = props
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  });

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ]
    };

    http.get(`channel`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setData((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  };

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(() => {
    getData()
  }, [])

  const checkSelect = (channel_id) => {
    if(value?.channel_id === channel_id){
      return true
    }else{
      return false
    }
  }

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Name</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="4" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell>{item.channel_name}</CTableDataCell>
                            
                            <CTableDataCell className="text-center">
                              <CButton 
                                color={checkSelect(item.channel_id)?'secondary':'success'} 
                                className="text-white" 
                                size='sm'
                                disabled={checkSelect(item.channel_id)}
                                onClick={()=>onSelect(item)}
                              >
                                {checkSelect(item.channel_id)?'Selected':'Select'}
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

ChannelList.propTypes = {
  path: PropTypes.string,
  onSelect: PropTypes.func,
}

export default ChannelList