import { CSpinner } from '@coreui/react';
import React from 'react'
import './css/loader.css';

const LoaderSpinner = (props) => {
  if(props.visible){
    return(
      <div className="wrapper-loader d-flex justify-content-center align-items-center">
        <CSpinner color="info"/>
      </div>
    )
  }else{
    return null;
  }
}

export default LoaderSpinner;