import React, { useEffect, useState } from 'react'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilSave, cilX
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import SelectChannel from 'src/components/SelectChannel'
import SelectInventory from 'src/components/SelectInventory'
import ImageInput from 'src/components/ImageInput'
import { SketchPicker } from 'react-color'

const MySwal = withReactContent(Swal)

const PlacementForm = () => {
  const history = useHistory()
  const { placement_id } = useParams()
  const { state } = useLocation()
  const { type, data } = state
	const [loading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState({
    name: '',
    channel: '',
    inventory: [],
    background_image: ''
  })
  const [label, setLabel] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(true)
  const [isColor, setIsColor] = useState(false)
  const [color, setColor] = useState('#fff')
  
  const getCategory = () => {
    setCategoryLoading(true)
    http.get(`category/recursive/${dataForm.channel.category_id}`)
    .then((res) => {
      console.log('res', res.data)

      if(res.data.status) {
        if(type==='edit'){
          var newLabel = []
          var category_data_temp = res.data.data
          for(var i=0; i<res.data.label.length; i++){
            var category_data = [];
            if(i === 0){
              category_data = res.data.data
            }else if(i > 0){
              //execute child data
              if(data.category[i-1]){
                const idxParent = category_data_temp.findIndex(x=>x.category_id===data.category[i-1].category_id)
                if(idxParent >= 0){
                  const children = category_data_temp[idxParent].children
                  category_data = children
                  category_data_temp = children
                }else{
                  category_data = []
                  category_data_temp = []
                }
              }else{
                category_data = []
                category_data_temp = []
              }
            }

            newLabel.push({
              name: res.data.label[i],
              value: data.category[i]?data.category[i].category_id:'',
              data: category_data
            })
          }
          setLabel(newLabel)
        }else{
          setLabel(res.data.label?.map(x=>({name: x, value: '', data: res.data.data})))
        }
      }
      setCategoryLoading(false)
    })
    .catch((error) => {
      setCategoryLoading(false)
      history.goBack()
    });
  }

  useEffect(()=>{
    if(type==='edit'){
      setDataForm(state => ({
        ...state,
        name: data.placement_name,
        channel: data.channel,
        inventory: data.inventory.map(x=>x.inventory_detail),
        background_image: data.background_image
      }))
      if(data.background_color){
        setIsColor(true)
        setColor(data.background_color)
      }
    }
  }, [])

  useEffect(()=>{
    if(dataForm.channel.category_id){
      getCategory()
    }
  }, [dataForm.channel])

  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {type==='add'?'Add':'Edit'} Master Placement
              </h4>
              <div className="small text-medium-emphasis">Master Placement Data</div>
            </CCol>
          </CRow>
          <CRow className="mt-3 mb-4">
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Channel</CFormLabel>
                <SelectChannel
                  value={dataForm.channel}
                  onSelect={(val)=>{
                    setDataForm(state => ({...state, channel:val}))
                    if(val.channel_id!==dataForm.channel?.channel_id){
                      setLabel([])
                      setDataForm(state => ({...state, inventory:[]}))
                    }
                  }}
                />
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Name</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Name"
                  value={dataForm.name}
                  onChange={(txt)=>setDataForm(state => ({...state, name: txt.target.value}))}
                />
              </div>
            </CCol>
            {
              dataForm.channel!=='' &&
              <CCol md={12}>
                <div className="mb-3">
                  <CFormLabel>Inventory</CFormLabel>
                  <SelectInventory
                    value={dataForm.inventory}
                    onSelect={(data)=>setDataForm(state => ({...state, inventory:data}))}
                    disabled={dataForm.channel===''?true:false}
                    channel_id={dataForm.channel.channel_id}
                    multiple
                  />
                </div>
              </CCol>
            }
            {
              dataForm.channel.category_id &&
              <CCol md={12}>
                <div className="mb-3">
                  <CFormLabel><b>Category</b></CFormLabel>
                  <CRow>
                    {
                      label.map((item, index)=>{
                        return(
                          <CCol key={index} xs={6} md={3}>
                            <CFormLabel>{item.name}</CFormLabel>
                            {
                              !categoryLoading &&
                              <CFormSelect
                                value={item.value}
                                disabled={index>0&&label[index-1].value===""?true:false}
                                id={`select-${index}`}
                                onChange={(val)=>categorySelected(val.target.value, index, item.data)}
                              >
                                <option value="">Select</option>
                                {
                                  item?.data?.map((row, idx) => {
                                    return(
                                      <option key={idx} value={row.category_id}>{row.category_name}</option>
                                    )
                                  })
                                }
                              </CFormSelect>
                            }
                          </CCol>
                        )
                      })
                    }
                  </CRow>
                </div>
              </CCol>
            }
            <CCol md={6}>
              <CFormLabel>Background</CFormLabel>
              <div className="d-flex flex-row">
                <CFormCheck
                  type="radio"
                  label="Color"
                  checked={isColor===true}
                  onChange={()=>setIsColor(true)}
                />
                <CFormCheck
                  type="radio"
                  label="Image"
                  className="ms-3"
                  checked={isColor===false}
                  onChange={()=>setIsColor(false)}
                />
              </div>
              {
                isColor?(
                  <div className='mt-3'>
                    <SketchPicker
                      color={color}
                      onChange={(color)=>setColor(color.hex)}
                    />
                  </div>
                ):(
                  <div className='mt-3'>
                    <ImageInput
                      disabled={loading} 
                      value={dataForm.background_image}
                      onChange={(data)=>setDataForm((state) => ({...state, background_image: data.url }))}
                    />
                  </div>
                )
              }
            </CCol>
          </CRow>
          
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
          <CButton 
            color="danger" 
            className="text-white ms-2"
            onClick={()=>history.goBack()}
          >
            <CIcon icon={cilX} /> &nbsp; Cancel
          </CButton>
        </CCardBody>
      </CCard>
    </>
  )

  function categorySelected(value, index, parent_data){
    if(label[index]['value']!==value){
      var _label = [...label]
      _label[index]['value'] = value

      if(index<label.length-1){
        if(value!==''){
          const idxParent = parent_data.findIndex(x=>x.category_id===parseInt(value))
          _label[index+1]['data'] = parent_data[idxParent]['children']
        }

        for(var i=index+1; i < label.length; i++ ){
          _label[i]['value'] = ''
        }
      }

      setLabel(_label)
    }
  }

  function create() {
    setLoading(true);
    var data_post = {
      placement_name: dataForm.name,
      channel_id: dataForm.channel.channel_id,
      inventory_id: dataForm.inventory.map(x=>x.inventory_id),
      category_id: dataForm.channel?.category_id?label.map(x=>x.value):[],
      background_image: isColor?null:dataForm.background_image,
      background_color: isColor?color:null
    }

    http.post('placement', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      placement_name: dataForm.name,
      channel_id: dataForm.channel.channel_id,
      inventory_id: dataForm.inventory.map(x=>x.inventory_id),
      category_id: dataForm.channel?.category_id?label.map(x=>x.value):[],
      background_image: isColor?null:dataForm.background_image,
      background_color: isColor?color:null
    }
    http.put('placement/'+placement_id, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default PlacementForm