import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormLabel,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilPencil,
  cilPlus,
  cilTrash
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'
import { useHistory } from 'react-router-dom'
import SelectChannel from 'src/components/SelectChannel'
import SelectInventory from 'src/components/SelectInventory'

const MySwal = withReactContent(Swal)
const pageRow = 10

const PlacementList = () => {
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })
  const [channel, setChannel] = useState('')
  const [label, setLabel] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(true)
  const [inventory, setInventory] = useState('')

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ]
    }

    if(channel!==''){
      data_post['channel_id'] = channel.channel_id
    }

    if(inventory!==''){
      data_post['inventory_id'] = inventory.inventory_id
    }

    const lebelValue = label.filter(x=>x.value!=='');
    if(lebelValue.length > 0){
      data_post['category_id'] = lebelValue[lebelValue.length-1].value
    }

    http.get(`placement`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setData((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  }

  const deleteData = (placement_id) => {
    setLoading(true);

    http.put('placement/delete/'+placement_id)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        getData()

        MySwal.fire({
          heightAuto: false,
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          heightAuto: false,
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  const getCategory = () => {
    setCategoryLoading(true)
    http.get(`category/recursive/${channel.category_id}`)
    .then((res) => {
      if(res.data.status) {
        setLabel(res.data.label?.map(x=>({name: x, value: '', data: res.data.data})))
      }
      setCategoryLoading(false)
    })
    .catch((error) => {
      setCategoryLoading(false)
    });
  }

  const categorySelected = (value, index, parent_data) => {
    if(label[index]['value']!==value){
      var _label = [...label]
      _label[index]['value'] = value

      if(index<label.length-1){
        if(value!==''){
          const idxParent = parent_data.findIndex(x=>x.category_id===parseInt(value))
          _label[index+1]['data'] = parent_data[idxParent]['children']
        }

        for(var i=index+1; i < label.length; i++ ){
          _label[i]['value'] = ''
        }
      }

      setLabel(_label)
    }
  }

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(()=>{
    if(channel.category_id){
      getCategory()
    }
    getData()
  }, [channel])

  useEffect(()=>{
    if(inventory!==''){
      getCategory()
    }
    getData()
  }, [inventory])

  useEffect(()=>{
    if(label.length > 0){
      getData()
    }
  }, [label])

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol md={8}>
              <h4 id="traffic" className="card-title mb-0">Placement</h4>
              <div className="small text-medium-emphasis">List</div>
            </CCol>
            <CCol md={4} className="d-none d-md-block">
              <div className='d-flex justify-content-end'>
                <CButton
                  color="info" 
                  className="float-end text-white ms-4" 
                  onClick={()=>history.push({
                    pathname: `/placement/add`,
                    state: { type: 'add' }
                  })}
                >
                  <CIcon icon={cilPlus} /> Create Placement
                </CButton>
              </div>
            </CCol>
          </CRow>
          <div className='mt-3'>
            <CRow>
              <CCol xs={6}>
                <CFormLabel>Channel</CFormLabel>
                <SelectChannel
                  value={channel}
                  onSelect={(val)=>{
                    setChannel(val)
                    setInventory('')
                    setLabel([])
                  }}
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel>Inventory</CFormLabel>
                <SelectInventory
                  value={inventory}
                  onSelect={(data)=>setInventory(data)}
                  disabled={channel===''?true:false}
                  channel_id={channel.channel_id}
                />
              </CCol>
            </CRow>
            {
              channel.category_id &&
              <div className="mb-3 mt-3">
                <CRow>
                  {
                    label.map((item, index)=>{
                      return(
                        <CCol key={index} xs={6} md={3}>
                          <CFormLabel>{item.name}</CFormLabel>
                          {
                            !categoryLoading &&
                            <CFormSelect
                              value={item.value}
                              disabled={index>0&&label[index-1].value===""?true:false}
                              id={`select-${index}`}
                              onChange={(val)=>categorySelected(val.target.value, index, item.data)}
                            >
                              <option value="">All</option>
                              {
                                item?.data?.map((row, idx) => {
                                  return(
                                    <option key={idx} value={row.category_id}>{row.category_name}</option>
                                  )
                                })
                              }
                            </CFormSelect>
                          }
                        </CCol>
                      )
                    })
                  }
                </CRow>
              </div>
            }
          </div>
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Channel</CTableHeaderCell>
                  <CTableHeaderCell>Name</CTableHeaderCell>
                  <CTableHeaderCell>Inventory</CTableHeaderCell>
                  <CTableHeaderCell>Category</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="5" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell>{item.channel.channel_name}</CTableDataCell>
                            <CTableDataCell>{item.placement_name}</CTableDataCell>
                            <CTableDataCell>
                              <h5>
                                {
                                  item.inventory.map((itm, idx) => {
                                    return(
                                      <CBadge key={`inv-${index}${idx}`} color="info" className="me-1 mt-1">{itm.inventory_detail.inventory_name}</CBadge>
                                    )
                                  })
                                }
                              </h5>
                            </CTableDataCell>
                            <CTableDataCell>{item.category.map(x=>x.category_detail.category_name).toString()}</CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CButton 
                                color="warning" 
                                className="text-white" 
                                size='sm'
                                onClick={()=>history.push({
                                  pathname: '/placement/edit/'+item.placement_id,
                                  state: { type: 'edit', data: item }
                                })}
                              >
                                <CIcon icon={cilPencil} />&nbsp; Edit
                              </CButton>
                              <CButton 
                                color="danger" 
                                className="text-white ms-2" 
                                size='sm'
                                onClick={()=>deleteData(item.placement_id)}
                              >
                                <CIcon icon={cilTrash} />&nbsp; Delete
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

export default PlacementList