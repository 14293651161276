import React, { useEffect, useState } from 'react'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilSave, cilX
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ImageInput from 'src/components/ImageInput'

const MySwal = withReactContent(Swal)

const ChannelForm = () => {
  const history = useHistory()
  const { channel_id } = useParams()
  const { state } = useLocation()
  const { type, data } = state
	const [loading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState({
    name: '',
    shorten: '',
    status: 1,
    category_id: "",
    icon: '',
  })
  const [category, setCategory] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(true)

  const loadCategory = () => {
    setCategoryLoading(true);

    http.get(`category/parent`)
    .then((res) => {
      console.log('category', res)
      if(res.data.status) {
        setCategory(res.data.data)
      }
      setCategoryLoading(false);
    })
    .catch((error) => {
      setCategoryLoading(false);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
    })
  }

  useEffect(()=>{
    loadCategory()
    if(type==='edit'){
      setDataForm(state => ({
        ...state,
        name: data.channel_name,
        shorten: data.shorten,
        status: data.status,
        category_id: data.category_id===null?"":data.category_id,
        icon: data.icon
      }))
    }
  }, [])

  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {type==='add'?'Add':'Edit'} Channel
              </h4>
              <div className="small text-medium-emphasis">Channel Data</div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Name</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Name"
                  value={dataForm.name}
                  onChange={(txt)=>setDataForm(state => ({...state, name: txt.target.value}))}
                />
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Shorten</CFormLabel>
                <CFormInput
                  type="input"
                  placeholder="Shorten"
                  value={dataForm.shorten}
                  onChange={(txt)=>setDataForm(state => ({...state, shorten: txt.target.value}))}
                />
              </div>
            </CCol>
            <CCol md={6}>
              <div className='mb-3'>
                <CFormLabel>Category</CFormLabel>
                {
                  categoryLoading?(
                    <div>Loading...</div>
                  ):(
                    <CFormSelect value={dataForm.category_id} onChange={(val)=>setDataForm(state => ({...state, category_id: val.target.value}))}>
                      <option value="">Without Category</option>
                      {
                        category.map((item, index) => {
                          return(
                            <option key={index} value={item.category_id}>{item.category_name}</option>
                          )
                        })
                      }
                    </CFormSelect>
                  )
                }
              </div>
            </CCol>
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Status</CFormLabel>
                <div className="d-flex flex-row">
                  <CFormCheck
                    type="radio"
                    label="Active"
                    checked={dataForm.status===1}
                    onChange={()=>setDataForm(state=>({...state, status: 1}))}
                  />
                  <CFormCheck
                    type="radio"
                    label="Inactive"
                    className="ms-3"
                    checked={dataForm.status===0}
                    onChange={()=>setDataForm(state=>({...state, status: 0}))}
                  />
                </div>
              </div>
            </CCol>
            <CCol md={3}>
              <div className='mb-3'>
                <CFormLabel>Icon</CFormLabel>
                <ImageInput
                  disabled={loading} 
                  value={dataForm.icon}
                  onChange={(data)=>setDataForm((state) => ({...state, icon: data.url }))}
                />
              </div>
            </CCol>
          </CRow>
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
          <CButton 
            color="danger" 
            className="text-white ms-2"
            onClick={()=>history.goBack()}
          >
            <CIcon icon={cilX} /> &nbsp; Cancel
          </CButton>
        </CCardBody>
      </CCard>
    </>
  )

  function create() {
    setLoading(true);
    var data_post = {
      channel_name: dataForm.name,
      status: dataForm.status,
      category_id: dataForm.category_id,
      shorten: dataForm.shorten,
      icon: dataForm.icon
    }

    http.post('channel', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      channel_name: dataForm.name,
      status: dataForm.status,
      category_id: dataForm.category_id,
      shorten: dataForm.shorten,
      icon: dataForm.icon
    }
    http.put('channel/'+channel_id, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default ChannelForm