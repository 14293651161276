import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilChevronRight, cilChevronLeft } from '@coreui/icons'
import ReactPaginate from 'react-paginate';

export const Pagination = ({ totalPage = 5, handleOnChange, page = 1 }) => {

  return (
    <div className='mt-2'>
      <ReactPaginate
        previousLabel={<CIcon icon={cilChevronLeft} />}
        nextLabel={<CIcon icon={cilChevronRight} />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handleOnChange}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
        forcePage={page - 1}
      />
    </div>
  )
}