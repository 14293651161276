import { CBadge, CCol, CModal, CModalBody, CModalHeader, CModalTitle, CRow } from "@coreui/react"
import React, { useEffect, useState } from "react"
import styled from 'styled-components';
import CIcon from "@coreui/icons-react";
import { cilX } from "@coreui/icons";
import InventoryList from "./InventoryList";

const SelectBox = styled.div`
  cursor: pointer
`;

const Placeholder = styled.div`
  color: #8b93a2
`;

const SelectInventory = (props) => {
  const [modal, setModal] = useState(false)
  const [value, setValue] = useState(props.multiple?[]:'')

  useEffect(()=>{
    setValue(props.value?props.value:(props.multiple?[]:''))
  }, [props.value])

  return(
    <div>
      <SelectBox style={props.disabled?{cursor:'default'}:{}}>
        <div className="form-select" style={props.disabled?{backgroundColor:'#eee'}:{}}>
          <CRow>
            <CCol>
              {
                !props.multiple ?(
                  <CRow>
                    <CCol>
                      <div onClick={()=>!props.disabled?setModal(true):null}>
                        {
                          value===''?(
                            <Placeholder>
                              Select Inventory
                            </Placeholder>
                          ):(
                            <div>{value.inventory_name}</div>
                          )
                        }
                      </div>
                    </CCol>
                    {
                      value!=="" &&
                      <CCol xs={'auto'}>
                        <div className="me-2" onClick={()=>{
                          setValue('')
                          props.onSelect('')
                        }}>
                          <CIcon icon={cilX}/>
                        </div>
                      </CCol>
                    }
                  </CRow>
                ):(
                  <div onClick={()=>!props.disabled?setModal(true):null}>
                    {
                      value.length===0?(
                        <Placeholder>
                          Select Inventory
                        </Placeholder>
                      ):(
                        <h4>
                          {
                            value.map((item, index) => {
                              return(
                                <CBadge key={index} color="info" className="me-1 mt-1">{item.inventory_name}</CBadge>
                              )
                            })
                          }
                        </h4>
                      )
                    }
                  </div>
                )
              }
            </CCol>
          </CRow>
        </div>
      </SelectBox>

      <CModal
        alignment="center"
        size="lg"
        visible={modal}
        onClose={() => setModal(false) }
      >
        <CModalHeader>
          <CModalTitle>Select Inventory</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <InventoryList
            channel_id={props.channel_id}
            value={value.length===0?[]:value}
            onSelect={(data)=>{
              setValue(data)
              props.onSelect(data)
              if(!props.multiple){
                setModal(false)
              }
            }}
            multiple={props.multiple}
          />
        </CModalBody>
      </CModal>
    </div>
  )
}

export default SelectInventory