import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'

const MySwal = withReactContent(Swal)
const pageRow = 10

const InventoryList = (props) => {
  const { onSelect, value, channel_id, multiple } = props
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  });
  const [itemSelected, setItemSelected] = useState(multiple?[]:'')

  useEffect(()=>{
    setItemSelected(value)
  }, [value])

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      channel_id: channel_id,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ]
    };

    http.get(`inventory`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setData((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  };

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(() => {
    getData()
  }, [])

  const checkSelect = (inventory_id) => {
    if(multiple){
      const check = itemSelected.findIndex(x=>x.inventory_id===inventory_id)
      if(check >= 0){
        return true
      }else{
        return false
      }
    }else{
      if(inventory_id===itemSelected?.inventory_id){
        return true
      }else{
        return false
      }
    }
  }

  const onSelectItem = (item) => {
    if(multiple){
      var newSelected = [...itemSelected]
      newSelected.push(item)
      setItemSelected(newSelected)
      onSelect(newSelected)
    }else{
      setItemSelected(item)
      onSelect(item)
    }
  }

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Name</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="4" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell>{item.inventory_name}</CTableDataCell>
                            
                            <CTableDataCell className="text-center">
                              <div className="d-flex align-items-center justify-content-center">
                                <CButton 
                                  color={checkSelect(item.inventory_id)?'secondary':'success'} 
                                  className="text-white" 
                                  size='sm'
                                  disabled={checkSelect(item.inventory_id)}
                                  onClick={()=>onSelectItem(item)}
                                >
                                  {checkSelect(item.inventory_id)?'Selected':'Select'}
                                </CButton>
                                {
                                  checkSelect(item.inventory_id) && multiple &&
                                  <CButton 
                                    color={'danger'} 
                                    className="text-white ms-2" 
                                    size='sm'
                                    onClick={()=>{
                                      const idcCheck = itemSelected.findIndex(x=>x.inventory_id===item.inventory_id)
                                      const newData = [...itemSelected]
                                      newData.splice(idcCheck, 1)
                                      setItemSelected(newData)
                                      onSelect(newData)
                                    }}
                                  >
                                    X
                                  </CButton>
                                }
                              </div>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

InventoryList.propTypes = {
  path: PropTypes.string,
  onSelect: PropTypes.func,
}

export default InventoryList