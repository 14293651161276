import React, { useEffect, useState } from 'react'
import {
  CBreadcrumb,
  CBreadcrumbItem,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilPencil,
  cilPlus,
  cilSave,
  cilTrash,
  cilX,
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { useHistory } from 'react-router-dom'
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import { Pagination } from 'src/components/Pagination'

const MySwal = withReactContent(Swal)
const pageRow = 10

const CategoryList = () => {
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })
  const [modalForm, setModalForm] = useState(null)
  const [name, setName] = useState('')
  const [label, setLabel] = useState([])
  const [parentId, setParentId] = useState(null)
  const [nav, setNav] = useState([])
  const [navLabel, setNavLabel] = useState([])

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ],
      parent_id: parentId
    }

    http.get(`category`,
      { params: data_post }
    )
    .then((res) => {
      console.log('res', res.data.parent)
      if(res.data.status) {
        setData((state) => ({
          ...state,
          data: res.data.data.data,
          page: res.data.data.page,
          lastPage: res.data.data.lastPage,
        }));

        const checkNav = nav.findIndex(x => x.parent_id===res.data.parent.parent_id )
        if(checkNav < 0){
          setNav([...nav, res.data.parent])
        }else{
          var navNew = [...nav]
          navNew.splice(checkNav+1, (nav.length-1)-((checkNav)))
          setNav(navNew)
        }
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
    });
  }

  useEffect(() => {
    getData()
  }, [parentId])

  useEffect(() => {
    if(modalForm===null){
      setName('')
      setLabel([])
    }
  }, [modalForm])

  const openModalForm = (type, data_category) => {
    if(type==='add'){
      setModalForm({
        type: 'add'
      })
    }else if(type==='edit'){
      setName(data_category.category_name)
      setLabel(data_category.label?JSON.parse(data_category.label):[])
      setModalForm({
        type: 'edit',
        category_id: data_category.category_id
      })
    }
  }

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CBreadcrumb>
          {
            nav.map((item, index) => {
              return(
                <CBreadcrumbItem
                  key={index}
                  active={index===nav.length-1}
                  className={[index!==nav.length-1?"text-primary":"", index!==nav.length-1?"pointer":""]}
                  onClick={()=>setParentId(item.parent_id)}
                >
                  {item.parent_name}
                </CBreadcrumbItem>
              )
            })
          }
          </CBreadcrumb>
          <CRow className='mt-3'>
            <CCol md={8}>
              <h4 id="traffic" className="card-title mb-0">{nav[nav.length-1]?.parent_name}</h4>
              <div className="small text-medium-emphasis">List</div>
            </CCol>
            <CCol md={4} className="d-none d-md-block">
              <div className='d-flex justify-content-end'>
                <CButton
                  color="info" 
                  className="float-end text-white ms-4"
                  onClick={()=>openModalForm('add')}
                >
                  <CIcon icon={cilPlus} /> Add Data
                </CButton>
              </div>
            </CCol>
          </CRow>
          <div className="relative mt-3 overflow-hidden">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>No</CTableHeaderCell>
                  <CTableHeaderCell>Name</CTableHeaderCell>
                  {
                    parentId==null &&
                    <CTableHeaderCell>Label</CTableHeaderCell>
                  }
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="4" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell>{index+1}</CTableDataCell>
                            <CTableDataCell>
                              <span
                                className={(nav.length-1)!==navLabel.length || !parentId?'text-primary fw-bold pointer':''}
                                onClick={()=>{
                                  if((nav.length-1)!==navLabel.length || !parentId){
                                    setParentId(item.category_id)
                                    if(item.parent_id===null){
                                      setNavLabel(JSON.parse(item.label))
                                    }
                                  }
                                }}
                              >
                                {item.category_name}
                              </span>
                            </CTableDataCell>
                            {
                              parentId==null &&
                              <CTableDataCell>{item.label}</CTableDataCell>
                            }
                            <CTableDataCell className="text-center">
                              <CButton 
                                color="warning" 
                                className="text-white" 
                                size='sm'
                                onClick={()=>openModalForm('edit', item)}
                              >
                                <CIcon icon={cilPencil} />&nbsp; Edit
                              </CButton>
                              <CButton 
                                color="danger" 
                                className="text-white ms-2" 
                                size='sm'
                                onClick={()=>deleteData(item.category_id)}
                              >
                                <CIcon icon={cilTrash} />&nbsp; Delete
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>

      <CModal
        alignment="center"
        visible={modalForm!==null?true:false}
        onClose={() => setModalForm(null)}
      >
        <CModalHeader>
          <CModalTitle>Add {parentId==null?"Category":"Data"}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className='mb-3'>
            <CFormLabel>Name</CFormLabel>
            <CFormInput
              type="input"
              placeholder="Name"
              value={name}
              onChange={(txt)=>setName(txt.target.value)}
            />
          </div>
          {
            parentId==null &&
            <div className='mb-3'>
              <CFormLabel>Label</CFormLabel>
              <InputTags elementClassName={'bg-primary'} placeholder="Label" values={label} onTags={(value) => setLabel(value.values)} />
              <p className="fw-light fst-italic fs-6">Press Enter or Space for add data</p>
            </div>
          }
        </CModalBody>
        <CModalFooter>
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>modalForm.type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
          <CButton 
            color="danger" 
            className="text-white ms-2"
            onClick={()=>setModalForm(null)}
          >
            <CIcon icon={cilX} /> &nbsp; Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )

  function deleteData(category_id){
    setLoading(true);

    http.put('category/delete/'+category_id)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        getData()

        MySwal.fire({
          heightAuto: false,
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          heightAuto: false,
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function create() {
    setLoading(true);
    var data_post = {
      parent_id: parentId,
      category_name: name,
      label: parentId===null?label:null
    }

    http.post('category', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        getData()
        setModalForm(null)
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      category_name: name,
      label: parentId===null?label:null
    }
    http.put('category/'+modalForm.category_id, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        getData()
        setModalForm(null)
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default CategoryList