import React, { useEffect } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { Redirect } from 'react-router-dom'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from "js-cookie";
import { useDispatch } from 'react-redux'

const DefaultLayout = () => {

  const dispatch = useDispatch()
  var token = Cookies.get('token')
  var user = JSON.parse(decrypt(Cookies.get('user')))

  useEffect(() => {
    if(user & token){
      dispatch({ type: 'set', user: user });
    }
  }, [user, token]);

  if(!token){
    return <Redirect to={'/login'}/>
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3 relative pt-4">
          <AppContent />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default DefaultLayout
