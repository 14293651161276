import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilPencil,
  cilPlus,
  cilSearch,
  cilTrash
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'
import { useHistory } from 'react-router-dom'

const MySwal = withReactContent(Swal)
const pageRow = 10

const ArticleList = () => {
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState([])
  const [categorySelected, setCategorySelected] = useState('')
  const [status, setStatus] = useState('')

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ],
      filter: [
        {
          field: "status",
          operator: "=",
          value: status
        },
        {
          field: "article_category_id",
          operator: "=",
          value: categorySelected
        },
        {
          field: "title",
          operator: "LIKE",
          value: `%${search}%`
        }
      ]
    }

    http.get(`article`,
      { params: data_post }
    )
    .then((res) => {
      console.log('res', res.data)
      if(res.data.status) {
        setData((state) => ({
          ...state,
          data: res.data.data.data,
          page: res.data.data.page,
          lastPage: res.data.data.lastPage,
        }));
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
    });
  }

  const deleteData = (article_id) => {
    setLoading(true);

    http.put('article/delete/'+article_id)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        getData()

        MySwal.fire({
          heightAuto: false,
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          heightAuto: false,
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  const getCategory = () => {
    var data_post = {
      page: 1,
      rows: 100,
      order: [
        {
          field: "article_category_name",
          sort: "ASC"
        }
      ]
    }

    http.get(`article_category`,
      { params: data_post }
    )
    .then((res) => {
      console.log('res article_category', res.data)
      if(res.data.status) {
       setCategory(res.data.data.data) 
      }
    })
    .catch((error) => {
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      history.goBack()
    })

  }

  useEffect(() => {
    getCategory()
  }, [])

  useEffect(()=>{
    getData()
  }, [status, categorySelected])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getData()
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol md={8}>
              <h4 id="traffic" className="card-title mb-0">Article Category</h4>
              <div className="small text-medium-emphasis">List</div>
            </CCol>
            <CCol md={4} className="d-none d-md-block">
              <div className='d-flex justify-content-end'>
                <CButton
                  color="info" 
                  className="float-end text-white ms-4" 
                  onClick={()=>history.push({
                    pathname: `/article/add`,
                    state: { type: 'add' }
                  })}
                >
                  <CIcon icon={cilPlus} /> Create Article Category
                </CButton>
              </div>
            </CCol>
          </CRow>

          <CRow className="mt-3">
            <CCol md={6}>
              <div className="mb-3">
                <CFormLabel className='mt-1'>Search</CFormLabel>
                <CInputGroup>
                  <CInputGroupText><CIcon icon={cilSearch}/></CInputGroupText>
                  <CFormInput
                    type="input"
                    placeholder="Search"
                    onChange={(txt)=>setSearch(txt.target.value)}
                  />
                </CInputGroup>
              </div>
            </CCol>
            <CCol md={3}>
              <div className="mb-3">
                <CFormLabel className='mt-1'>Category</CFormLabel>
                <CFormSelect
                  value={categorySelected}
                  onChange={(val)=>setCategorySelected(val.target.value)}
                >
                  <option value="">All</option>
                  {
                    category.map((item, index) => (
                      <option key={index} value={item.article_category_id}>{item.article_category_name}</option>
                    ))
                  }
                </CFormSelect>
              </div>
            </CCol>
            <CCol md={3}>
              <div className="mb-3">
                <CFormLabel className='mt-1'>Category</CFormLabel>
                <CFormSelect
                  value={status}
                  onChange={(val)=>setStatus(val.target.value)}
                >
                  <option value="">All</option>
                  <option value="1">Publish</option>
                  <option value="0">Unpublish</option>
                </CFormSelect>
              </div>
            </CCol>
          </CRow>
          
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell className="text-center">Image</CTableHeaderCell>
                  <CTableHeaderCell>Title</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Category</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Views</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="6" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell width="30px"><img src={item.image} height="120px" alt=""/></CTableDataCell>
                            <CTableDataCell>{item.title}</CTableDataCell>
                            <CTableDataCell className="text-center">{item.category.article_category_name}</CTableDataCell>
                            <CTableDataCell className="text-center"><CBadge color={item.status===1?"success":"danger"} shape="rounded-pill">{item.status===1?"Publish":"Unpublish"}</CBadge></CTableDataCell>
                            <CTableDataCell className="text-center">{item.views}</CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CButton 
                                color="warning" 
                                className="text-white" 
                                size='sm'
                                onClick={()=>history.push({
                                  pathname: '/article/edit/'+item.article_id,
                                  state: { type: 'edit', data: item }
                                })}
                              >
                                <CIcon icon={cilPencil} />&nbsp; Edit
                              </CButton>
                              <CButton 
                                color="danger" 
                                className="text-white ms-2" 
                                size='sm'
                                onClick={()=>deleteData(item.article_id)}
                              >
                                <CIcon icon={cilTrash} />&nbsp; Delete
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

export default ArticleList