import axios from "axios";
import { decrypt, encrypt } from "./Cryptograph";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_API
// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  
  failedQueue = [];
}

const http = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
})

http.interceptors.request.use(function (config) {
  const token = decrypt(Cookies.get("token"));
  config.headers['Authorization'] = token ? `bearer ${token}` : '';
  return config;
})

http.interceptors.response.use(undefined, error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({resolve, reject})
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return http.request(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = decrypt(Cookies.get("refreshToken"));
    const token = decrypt(Cookies.get("token"));

    return new Promise((resolve, reject) => {
      
      http.post('refresh_token', { refresh_token: refreshToken }, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        Cookies.set('token', encrypt(response.data.data.token))
        Cookies.set('refreshToken', encrypt(response.data.data.refreshToken))
        http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.data.token;
        originalRequest.headers['Authorization'] = 'Bearer ' + response.data.data.token;
        processQueue(null, response.data.data.token);
        resolve(http(originalRequest));
      })
      .catch((err) => {
        processQueue(err, null);
        reject(err);
      })
      .finally(() => { isRefreshing = false })

    })
  }
})

export default http;