import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilPencil,
  cilPlus,
  cilSearch,
  cilTrash
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'
import { useHistory } from 'react-router-dom'
import SelectChannel from 'src/components/SelectChannel'
import SelectInventory from 'src/components/SelectInventory'
import SelectPlacement from 'src/components/SelectPlacement'
import NumberFormat from 'src/service/NumberFormat'

const MySwal = withReactContent(Swal)
const pageRow = 12

const ProductList = () => {
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })
  const [channel, setChannel] = useState('')
  const [inventory, setInventory] = useState('')
  const [placement, setPlacement] = useState('')
  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      channel_id: channel?.channel_id,
      inventory_id: inventory?.inventory_id,
      placement_id: placement?.placement_id,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ],
      filter: [
        {
          field: "status",
          operator: "=",
          value: status
        },
        {
          field: "product_name",
          operator: "LIKE",
          value: `%${search}%`
        }
      ]
    }

    http.get(`product`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setData((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  }

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(()=>{
    getData()
  }, [channel, inventory, placement])

  const renderPice = (item) => {
    var price = NumberFormat(item.price);
    if(item.discount_type){
      if(item.discount_type==='amount'){
        price = NumberFormat(item.price-item.discount_amount)
      }else if(item.discount_type==='percentage'){
        price = NumberFormat(item.price - ((item.price*item.discount_amount)/100))
      }else if(item.discount_type==='price_after'){
        price = NumberFormat(item.discount_amount)
      }
    }
    return price
  }

  const deleteData = (product_id) =>{
    setLoading(true);

    http.put('product/delete/'+product_id)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        getData()

        MySwal.fire({
          heightAuto: false,
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          heightAuto: false,
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          heightAuto: false,
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  useEffect(()=>{
    getData()
  }, [status, channel, inventory, placement])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getData()
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol md={8}>
              <h4 id="traffic" className="card-title mb-0">Product</h4>
              <div className="small text-medium-emphasis">List</div>
            </CCol>
            <CCol md={4} className="d-none d-md-block">
              <div className='d-flex justify-content-end'>
                <CButton
                  color="info" 
                  className="float-end text-white ms-4" 
                  onClick={()=>history.push({
                    pathname: `/product/add`,
                    state: { type: 'add' }
                  })}
                >
                  <CIcon icon={cilPlus} /> Create Product
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md={3}>
              <CFormLabel className='mt-1'>Product Item Property</CFormLabel>
            </CCol>
            <CCol md={3}>
              <div className="mb-3">
                <SelectChannel
                  value={channel}
                  onSelect={(val)=>{
                    setChannel(val)
                    if(channel!==val){
                      setInventory('')
                      setPlacement('')
                    }
                  }}
                />
              </div>
            </CCol>
            <CCol md={3}>
              <div className="mb-3">
                <SelectInventory
                  value={inventory}
                  onSelect={(data)=>{
                    setInventory(data)
                    if(inventory!==data){
                      setPlacement('')
                    }
                  }}
                  disabled={channel===''?true:false}
                  channel_id={channel.channel_id}
                />
              </div>
            </CCol>
            <CCol md={3}>
              <div className="mb-3">
                <SelectPlacement
                  value={placement}
                  onSelect={(data)=>{
                    setPlacement(data)
                  }}
                  disabled={inventory===''?true:false}
                  inventory_id={inventory.inventory_id}
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={3}>
              <CFormLabel className='mt-1'>Product Status</CFormLabel>
            </CCol>
            <CCol md={3}>
              <div className="mb-3">
                <CFormSelect
                  value={status}
                  onChange={(val)=>setStatus(val.target.value)}
                >
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </CFormSelect>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={3}>
              <CFormLabel className='mt-1'>Product Search</CFormLabel>
            </CCol>
            <CCol md={4}>
              <div className="mb-3">
                <CInputGroup>
                  <CInputGroupText><CIcon icon={cilSearch}/></CInputGroupText>
                  <CFormInput
                    type="input"
                    placeholder="Search"
                    onChange={(txt)=>setSearch(txt.target.value)}
                  />
                </CInputGroup>
              </div>
            </CCol>
          </CRow>
          <div className="relative mt-3 product-box">
            <LoaderSpinner visible={loading}/>
            {
              data.data.length ===0 && !loading ? (
                <div className="text-center">Data not found</div>
              ) : (
                <CRow>
                  {
                    data.data.map((item, index) => {
                      return(
                        <CCol key={index} xs={12} md={4} xl={3}>
                          <div className="product-box h-100 d-flex flex-column">
                            <img src={item.image} className="image" alt=""/>
                            <div className="flex-grow-1 mt-3">
                              <div className="title">{item.product_name}</div>
                              <div className="desc mt-2">{item.product_short_description}</div>
                              <div className="text-center mt-2">
                                <CBadge color={item.status===1?"success":"danger"} shape="rounded-pill">{item.status===1?"Active":"Inactive"}</CBadge>
                              </div>
                              <div className="title mt-2">{item.main_item.quantity} {item.main_item.item_name}</div>
                              <div className="price mt-2">Rp {renderPice(item)}</div>
                              {
                                item.discount_type &&
                                <div className="price-line">Rp {NumberFormat(item.price)}</div>
                              }
                              <div className="text-center mt-2 fs-10"><strong>Includes:</strong></div>
                              {
                                item.includes.map((row, idx) => (
                                  <div key={'includes'+idx} className="text-center">• {row.item?.quantity} {row.item?.item_name}</div>
                                ))
                              }
                            </div>
                            <div className="mt-4 d-flex align-items-center justify-content-center">
                              <CButton 
                                color="warning" 
                                className="text-white flex-grow-1" 
                                size='sm'
                                onClick={()=>history.push({
                                  pathname: '/product/edit/'+item.product_id,
                                  state: { type: 'edit', data: item }
                                })}
                              >
                                <CIcon icon={cilPencil} />&nbsp; Edit
                              </CButton>
                              <div style={{width:10}}/>
                              <CButton 
                                color="danger" 
                                className="text-white flex-grow-1" 
                                size='sm'
                                onClick={()=>deleteData(item.product_id)}
                              >
                                <CIcon icon={cilTrash} />&nbsp; Delete
                              </CButton>
                            </div>
                          </div>
                        </CCol>
                      )
                    })
                  }
                </CRow>
              )
            }
            <hr/>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

export default ProductList